<template>
<div class="page">
    <b-card class="mb-2">
        <b-spinner class="align-middle"></b-spinner>
        <div class="text-center">
            載入中...
        </div>
    </b-card>
</div>
</template>
<script>
import abbottAndMixin from "@/mixins/liff/proj/abbott-and";
import { mapActions, mapGetters } from 'vuex';

export default {
  mixins: [abbottAndMixin],
  components: {},
  async mounted() {
    const query = this.$route.query;
    this.setQuery(
      {
        phone: query.phone,
        source: query.source,
        coupon_code: query.couponCode,
        qrcode: query.Id,
        store_code: query.storeCode,
        brand: query.brand,
        event_name: query.eventName,
        type: this.$route.params.couponType
      }
    )
    this.init();
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      queryData: "projAbbottAnd/queryData",
      couponType: "projAbbottAnd/couponType",
    }),
  },
  methods: {
    ...mapActions('projAbbottAnd', ['setQuery']),
    init() {
      console.log('進入 record query', this.$route.params.couponType)
      this.checkMember()
    },
  }
}
</script>

<style lang="scss"></style>
